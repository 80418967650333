@if (search().show) {
	<app-search
		[disabled]="disabled()"
		[placeholder]="search()?.options?.placeholder"
		(searchUpdated)="searchUpdated.emit($event)"
	></app-search>
}

<div class="flex flex-col flex-wrap z-50 h-auto">
	@if (checkboxesForm) {
		<div class="flex flex-col flex-wrap">
			<form
				[formGroup]="checkboxesForm"
				class="form-container lg:h-[300px] overflow-y-auto overflow-x-hidden text-xs w-full"
			>
				@if (options.length) {
					@if (showCheckAll()) {
						<div class="w-3/4 lg:w-full overflow-hidden">
							@let togglingCheckboxes = toggleAllCheckbox$ | async;
							<mat-checkbox
								color="primary"
								class="text-mainColor"
								#toggleAllCheckbox
								[checked]="togglingCheckboxes === CheckboxSelected.ALL"
								[disabled]="disabled()"
								[indeterminate]="togglingCheckboxes === CheckboxSelected.SOME"
								(change)="toggleAll($event.checked)"
							>
								{{ toggleAllCheckbox.checked ? 'Odznacz wszystko' : 'Zaznacz wszystko' }}
							</mat-checkbox>
						</div>
					}
				} @else {
					<p class="h-full flex justify-center items-center text-xl text-mainColor">Brak wyników</p>
				}
				<div class="w-3/4 lg:w-full overflow-hidden flex flex-col">
					@for (option of options; track option.id) {
						<mat-checkbox
							color="primary"
							[disabled]="disabled()"
							[formControlName]="option.id"
							class="text-mainColor"
						>
							{{ option.name }}
						</mat-checkbox>
					}
				</div>
			</form>
		</div>
	}
</div>
