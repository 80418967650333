import { BaseFilter } from '@/_store/offers/offers.types';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, input, output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, map, merge } from 'rxjs';

export interface CheckboxFilterValue {
	id: number;
	value: boolean;
}

export enum CheckboxSelected {
	ALL = 'all',
	SOME = 'some',
	NONE = 'none',
}

interface Search {
	show: boolean;
	options?: {
		placeholder?: string;
	};
}

@Component({
	selector: 'app-multi-choice',
	templateUrl: './multi-choice.component.html',
	styleUrls: ['./multi-choice.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiChoiceComponent implements OnDestroy {
	showCheckAll = input(true);
	search = input<Search>({
		show: false,
	});
	disabled = input(false);

	options: BaseFilter[];
	@Input() set _options(options: BaseFilter[]) {
		if (!Array.isArray(options)) {
			return;
		}

		this.options = options;
		this.updateToggleAllCheckboxState();
		if (!this.checkboxesForm) {
			this.createCheckboxesControls(options);
		}
	}

	selected: number[];
	@Input() set _selected(selected: number[]) {
		if (!Array.isArray(selected)) {
			return;
		}

		this.selected = selected;
		this.options.forEach((option) => {
			this.checkboxesForm.get(option.id.toString()).setValue(selected.includes(option.id), { emitEvent: false });
		});
		this.updateToggleAllCheckboxState();
	}

	filterValue = output<CheckboxFilterValue>();
	searchUpdated = output<string>();

	toggleAllCheckbox$: BehaviorSubject<CheckboxSelected> = new BehaviorSubject<CheckboxSelected>(
		CheckboxSelected.NONE,
	);
	checkboxesForm: FormGroup;
	CheckboxSelected = CheckboxSelected;

	private readonly destroy$: Subject<void> = new Subject<void>();

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	toggleAll(checked: boolean): void {
		Object.keys(this.checkboxesForm.controls).forEach((key) => {
			if (this.filteredControlValues.find((v) => v.id === key)) {
				this.checkboxesForm.get(key)?.patchValue(checked);
			}
		});
	}

	private createCheckboxesControls(baseFilterArr: BaseFilter[]): void {
		if (!baseFilterArr?.length) {
			return;
		}
		this.checkboxesForm = new FormGroup({});
		baseFilterArr.forEach(({ id }) => {
			this.checkboxesForm.addControl(
				id.toString(),
				new FormControl({
					value: false,
					disabled: false,
				}),
			);
		});

		merge(
			...this.options.map((option) =>
				this.checkboxesForm.get(option.id.toString()).valueChanges.pipe(
					map((value) => ({
						id: option.id,
						value,
					})),
				),
			),
		).subscribe((value) => {
			this.filterValue.emit(value);
			this.updateToggleAllCheckboxState();
		});
	}

	private get filteredControlValues(): { id: string; value: boolean }[] {
		let controlValues: { id: string; value: boolean }[] = [];

		Object.keys(this.checkboxesForm.controls).forEach((key) => {
			if (this.options.find((v) => v.id === +key)) {
				controlValues = [...controlValues, { id: key, value: this.checkboxesForm.controls[key].value }];
			}
		});
		return controlValues;
	}

	private updateToggleAllCheckboxState(): void {
		if (!this.checkboxesForm) return;

		const areAllControlsChecked = this.filteredControlValues.every((control) => control.value);
		const anyControlChecked = this.filteredControlValues.some((control) => control.value);

		let checkboxState: CheckboxSelected = CheckboxSelected.NONE;
		if (areAllControlsChecked && this.filteredControlValues.length > 0) {
			checkboxState = CheckboxSelected.ALL;
		} else if (anyControlChecked) {
			checkboxState = CheckboxSelected.SOME;
		}

		this.toggleAllCheckbox$.next(checkboxState);
	}
}
